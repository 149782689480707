import Header from '../../components/header';
import Table from '../../components/table';

import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import {
  UserCell,
  CardCell,
} from '../../components/table/renderers';
import { getAllUsers } from '../../services/api';
import UserModal from '../../components/userModal';

const MyMembers = (props) => {
  const [ users, setUsers ] = useState(null);
  const [ showUserModal, setShowUserModal ] = useState(false);
  const [ userData, setUserData ] = useState(null);

  const fetchData = async () => {
    const newUsers = await getAllUsers();
    setUsers(newUsers);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      Header: 'Prénom / Nom',
      accessor: 'fullname',
      Cell: UserCell,
    },
    {
      Header: 'Métier',
      accessor: 'job',
      Cell: (props) => {
        return (
          <p>{props.value?.length > 20 ? props.value.slice(0, 20) + '...' : props.value}</p>
        )
      }
    },
    {
      Header: 'Sphère',
      accessor: 'sphere',
    },
    {
      Header: 'Entreprise',
      accessor: 'company',
    },
    {
      Header: 'Ville',
      accessor: 'city',
    },
    {
      Header: 'Téléphone',
      accessor: 'phone',
    },
    {
      Header: 'Fiche',
      accessor: 'informations',
      Cell: (props) => <CardCell {...props} onClick={() => {
        setShowUserModal(true);
        setUserData(props.row.original);
      }} />,
    },
  ];

  return (
    <div className={styles.container}>
      <Header title={'Mes membres'}/>
      {users && <Table columns={columns} data={users}/>}
      <UserModal isOpen={showUserModal} onRequestClose={() => setShowUserModal(false)} data={userData}/>
    </div>
  )
}

export default MyMembers;
