import Header from '../../components/header';
import Table from '../../components/table';

import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import {
  SinceCell,
  ButtonCell, UserCell, NetworksCell, CardCell, StatusCell, statusOptions,
} from '../../components/table/renderers';
import UserModal from '../../components/userModal';
import { getProspects, setProspectStatus, getCityGroups } from '../../services/api';
import Select from 'react-select';

const Prospects = (props) => {
  const [ users, setUsers ] = useState(null);
  const [ showUserModal, setShowUserModal ] = useState(false);
  const [ userData, setUserData ] = useState(null);
  const [ cityGroups, setCityGroups ] = useState(null);
  const [ cityGroup, setCityGroup ] = useState(null);
  const [ admin, setAdmin ] = useState(null);
  const [ status, setStatus ] = useState(null);
  const [ filteredUsers, setFilteredUsers ] = useState(null);
  const [ sort, setSort ] = useState(null);

  const fetchData = async () => {
    const newUsers = await getProspects();
    setUsers(newUsers);
    const cityGroups = await getCityGroups();
    setCityGroups(cityGroups);
  }

  const filterCityGroup = ({ value }) => {
    setCityGroup(value);
  }

  const filterAdmin = ({ value }) => {
    setAdmin(value);
  }

  const filterStatus = ({ value }) => {
    setStatus(value);
  }

  useEffect(() => {
    if (!users) return;
    let data = [ ...users ];
    if (cityGroup) {
      data = data.filter(u => !!u.city_group && u.city_group.id === cityGroup);
    }
    if (admin) {
      data = data.filter(u => (u.city_group?.admins?.length) && (u.city_group.admins[0].id === admin));
    }
    if (status) {
      data = data.filter(u => u.prospectStatus === status);
    }
    if (sort) {
      switch (sort) {
        case 'createdAtAsc':
          data = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          break;
        case 'createdAtDesc':
          data = data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          break;
        case 'prospectStatusAsc':
          data = data.sort((a, b) => a.prospectStatus.localeCompare(b.prospectStatus));
          break;
        case 'prospectStatusDesc':
          data = data.sort((a, b) => b.prospectStatus.localeCompare(a.prospectStatus));
          break;
        case 'city_group':
          data = data.sort((a, b) => (a.city_group) ? a.city_group.name.localeCompare(b.city_group?.name) : -1);
          break;
        case 'admin':
          data = data.sort((a, b) => (a.city_group_admin && b.city_group_admin) ? a.city_group_admin.firstname.localeCompare(b.city_group_admin.firstname) : (a.city_group_admin ? -1 : (b.city_group_admin ? 1 : 2)));
          break;
        default:
          break;
      }

    }
    setFilteredUsers(data);
  }, [ users, cityGroup, admin, status, sort ]);

  useEffect(() => {
    fetchData();
  }, []);

  const sendSetProspectStatus = async (userId, status) => {
    try {
      await setProspectStatus(userId, status);
    } catch (err) {
      console.error('Error onboarding user', userId);
    } finally {
      await fetchData();
    }
  };

  const columns = [
    {
      Header: 'Prénom / Nom',
      accessor: 'fullname',
      Cell: UserCell,
    },
    {
      Header: 'LD',
      accessor: 'city_group_admin',
      Cell: (props) => <UserCell {...props} image={props.row.original.city_group_admin?.picture_url} name={props.row.original.city_group_admin ? `${props.row.original.city_group_admin.firstname} ${props.row.original.city_group_admin.lastname}` : '-'}/>,
    },
    {
      Header: 'CC',
      accessor: 'city_group.name',
    },
    {
      Header: 'Statut',
      accessor: 'prospectStatus',
      Cell: (props) => <StatusCell {...props} onChange={({ value }) => {
        sendSetProspectStatus(props.row.original.id, value);
      }
      }/>,
    },
    {
      Header: 'Fiche',
      accessor: 'informations',
      Cell: (props) => <CardCell {...props} onClick={() => {
        setShowUserModal(true);
        setUserData(props.row.original);
      }} />,
    },
    {
      Header: 'Depuis',
      accessor: 'createdAt',
      Cell: SinceCell
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: (props) => <ButtonCell {...props} text={'Supprimer'} onClick={() => {
        sendSetProspectStatus(props.row.original.id, 'ABANDONNED');
      }} />
    }
  ];

  return (
    <div className={styles.container}>
      <Header title={'Prospects'}/>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <h2 className={styles.filtersTitle}>Filtres :</h2>
          <span>CC</span>
          {!!cityGroups && <Select
            className={styles.select}
            options={[ { label: 'Tous', value: null }, ...cityGroups.map(cg => ({ label: cg.name, value: cg.id })) ]}
            defaultValue={{ label: 'Tous', value: null }}
            onChange={filterCityGroup}
          />}
        </div>
        <div className={styles.filter}>
          <span>LD</span>
          {!!cityGroups && <Select
            className={styles.select}
            options={[ { label: 'Tous', value: null }, ...cityGroups.filter(cg => cg.admins?.length).map(cg => ({ label: `${cg.admins[0].firstname} ${cg.admins[0].lastname}`, value: cg.admins[0].id })).filter((x, i, a) => a.findIndex(y => x.value === y.value) === i) ]}
            defaultValue={{ label: 'Tous', value: null }}
            onChange={filterAdmin}
          />}
        </div>
        <div className={styles.filter}>
          <span>Statut</span>
          <Select
            className={styles.select}
            options={[ { label: 'Tous', value: null }, ...statusOptions ]}
            defaultValue={{ label: 'Tous', value: null }}
            onChange={filterStatus}
          />
        </div>
        <div className={styles.filter}>
          <span>Trier par</span>
          <Select
            className={styles.select}
            options={[ { label: 'Date (+ récents)', value: 'createdAtAsc' }, { label: 'Date (+ anciens)', value: 'createdAtDesc' }, { label: 'Statut (a-z)', value: 'prospectStatusAsc' }, { label: 'Statut (z-a)', value: 'prospectStatusDesc' }, { label: 'CC', value: 'city_group' }, { label: 'LD', value: 'admin' } ]}
            defaultValue={{ label: 'Date (+ récents)', value: 'createdAtAsc' }}
            onChange={({ value }) => setSort(value)}
          />
        </div>
      </div>
      {filteredUsers && <Table columns={columns} data={filteredUsers}/>}
      <UserModal isOpen={showUserModal} onRequestClose={() => setShowUserModal(false)} data={userData}/>
    </div>
  )
}

export default Prospects;
