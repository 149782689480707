import Header from '../../components/header';
import Table from '../../components/table';
import Switch from 'react-switch';

import styles from './style.module.scss';
import { useCallback, useEffect, useState } from 'react';
import {
  DateCell,
  NetworkCell,
  CardCell,
  NumberCell,
} from '../../components/table/renderers';
import { getNetworks } from '../../services/api';
import UserModal from '../../components/userModal';
import NetworkModal from '../../components/networkModal';

const MyNetworks = (props) => {
  const [ allDepartment, setAllDepartment ] = useState(false);
  const [ networks, setNetworks ] = useState(null);
  const [ showNetworkModal, setShowNetworkModal ] = useState(false);
  const [ networkData, setNetworkData ] = useState(null);

  const fetchData = useCallback(async () => {
    const networks = await getNetworks(allDepartment);
    setNetworks(networks);
  }, [ allDepartment ]);

  useEffect(() => {
    fetchData();
  }, [ fetchData, allDepartment ]);

  const columns = [
    {
      Header: 'Nom du réseau',
      accessor: 'name',
      Cell: NetworkCell,
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Secteur',
      accessor: 'cityGroup',
    },
    {
      Header: 'Nombre d\'events',
      accessor: 'events',
      Cell: NumberCell,
    },
    {
      Header: 'Nombre de media',
      accessor: 'media',
      Cell: NumberCell,
    },
    {
      Header: 'Nombre de membres',
      accessor: 'members',
      Cell: NumberCell,
    },
    {
      Header: 'Membres Othr',
      accessor: 'othrs',
      Cell: NumberCell,
    },
    {
      Header: 'Partenaire depuis',
      accessor: 'createdAt',
      Cell: DateCell,
    },
    {
      Header: 'Contact',
      accessor: 'id',
      Cell: (props) => <CardCell {...props} onClick={() => {
        setShowNetworkModal(true);
        setNetworkData(props.row.original);
      }} />,
    },
  ];

  return (
    <div className={styles.container}>
      <Header title={'Regroupements'}/>
      <label className={styles.label}>
        <Switch
          checked={allDepartment}
          onChange={() => setAllDepartment(!allDepartment)}
          checkedIcon={false}
          uncheckedIcon={false}
        />
        <span className={styles.showAll}>Afficher les regroupements de tout le département</span>
      </label>
      {networks && <Table columns={columns} data={networks}/>}
      <NetworkModal isOpen={showNetworkModal} onRequestClose={() => setShowNetworkModal(false)} data={networkData}/>
    </div>
  )
}

export default MyNetworks;
