import { useRouteError } from 'react-router-dom';

import styles from './style.module.scss';

const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Erreur inconnue</h1>
      <h3 className={styles.subtitle}>Merci de contacter Othr</h3>
      <p className={styles.error}>{error.statusText || error.message}</p>
    </div>
  )
};

export default ErrorPage;
