import Header from '../../components/header';
import Table from '../../components/table';

import styles from './style.module.scss';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FollowTableCell } from '../../components/table/renderers';
import { getEssentialProfile, getFollowTable } from '../../services/api';
import Select from 'react-select';

const months = moment.monthsShort();

const options = (world) => {
  const options = [];
  const date = moment().date(1);
  const firstDate = moment('2023-01-01');
  while (date.isSameOrAfter(firstDate)) {
    options.push({
      label: world ? date.format('MMMM yy').toUpperCase() : date.format('yyyy'),
      value: date.format('yyyy-MM-DD'),
    });
    date.subtract(1, world ? 'month' : 'year');
  }
  console.log('options', options);
  return options;
}

const MyFollowTable = (props) => {
  const [ user, setUser ] = useState(null);
  const [ data, setData ] = useState(null);
  const [ date, setDate ] = useState({ value: moment().format('yyyy-MM-DD') });

  const fetchData = async () => {
    const user = await getEssentialProfile();
    setUser(user);
    console.log('date', date);
    const data = await getFollowTable(moment(date.value).format('MM'), moment(date.value).format('yyyy'));
    setData(data);
  }

  useEffect(() => {
    setDate(options(user?.world)[0]);
  }, [ user?.world ]);

  useEffect(() => {
    fetchData();
  }, [ date ]);

  const columns = user?.world ? [
    { Header: 'OD/LD', accessor: 'title', Cell: FollowTableCell, width: 800 },
    { Header: 'Réseaux', accessor: 'networks', Cell: FollowTableCell, width: 400 },
    { Header: 'Events', accessor: 'events', Cell: FollowTableCell, width: 400 },
    { Header: 'Comm. events', accessor: 'eventsComm', Cell: FollowTableCell, width: 400 },
    { Header: 'Places', accessor: 'eventsPlaces', Cell: FollowTableCell, width: 400 },
    { Header: 'Nvx membres', accessor: 'newMembers', Cell: FollowTableCell, width: 400 },
    { Header: 'Contactés', accessor: 'newMembersContacted', Cell: FollowTableCell, width: 400 },
    { Header: 'Prospects', accessor: 'prospects', Cell: FollowTableCell, width: 400 },
    { Header: 'Contactés', accessor: 'prospectsContacted', Cell: FollowTableCell, width: 400 },
    { Header: 'Membres 360€', accessor: 'fullPriceMembers', Cell: FollowTableCell, width: 400 },
    { Header: 'Membres 180€', accessor: 'halfPriceMembers', Cell: FollowTableCell, width: 400 },
    { Header: 'Membres 0€', accessor: 'freeMembers', Cell: FollowTableCell, width: 400 },
    { Header: 'Renouvellements', accessor: 'renewals', Cell: FollowTableCell, width: 400 },
    { Header: 'CA cumulé', accessor: 'totalCA', Cell: FollowTableCell, width: 400 },
    { Header: 'CA mensuel', accessor: 'monthCA', Cell: FollowTableCell, width: 400 },
    { Header: 'Comm OD cumulée', accessor: 'totalComm', Cell: FollowTableCell, width: 400 },
    { Header: 'Comm OD mensuelle', accessor: 'monthComm', Cell: FollowTableCell, width: 400 },
    { Header: 'Comm Othr cumulée', accessor: 'totalCommOthr', Cell: FollowTableCell, width: 400 },
    { Header: 'Comm Othr mensuelle', accessor: 'monthCommOthr', Cell: FollowTableCell, width: 400 },
  ] : [{
    Header: !!user && ((user.world && 'OD') || user.administratedZone?.name || (user.administratedCityGroups?.length > 1 ? `${user.administratedCityGroups.length} localités` : user.administratedCityGroups?.[0]?.name))?.toUpperCase(),
    accessor: 'title',
    Cell: FollowTableCell,
  }, ...months.map((m, i) => (
    {
      Header: `${m.slice(0, 1).toUpperCase()}${m.slice(1).split('.')[0]} ${moment(date.value).format('yy')}`,
      accessor: `${i}`,
      Cell: FollowTableCell,
    })),
  ];

  return (
    <div className={styles.container}>
      <Header title={'Mon tableau de suivi'}/>
      <Select
        className={styles.select}
        options={options(user?.world)}
        value={date}
        onChange={setDate}
      />
      {data && <Table columns={columns} data={data}/>}
    </div>
  )
}

export default MyFollowTable;
