import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import styles from './App.module.scss';
import Menu from './components/menu';
import { useEffect } from 'react';
import { getToken } from './services/api';

function App() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const authenticate = async () => {
    const authCode = (new URLSearchParams(search)).get('auth_code');
    if (!authCode) return;
    console.log('authCode', authCode);
    await getToken(authCode);
    navigate('/');
  }
  useEffect(() => {
    console.log('app created');
    authenticate();
  }, []);

  return (
    <div className={styles.container}>
      <Menu/>
      <div id={'detail'} className={styles.content}>
        <Outlet/>
      </div>
    </div>
  );
}

export default App;
