import styles from './style.module.scss';
import { useEffect, useState } from 'react';

import { getEssentialProfile } from '../../services/api';

const Header = (props) => {
  const [ picture, setPicture ] = useState(null);

  const getPicture = async () => {
    const user = await getEssentialProfile();
    setPicture(user.picture_url);
  }

  useEffect(() => {
    getPicture();
  }, []);

  return (
    <header className={styles.container}>
      <h2 className={styles.title}>{props.title}</h2>
      <div className={styles.shadow}/>
      <div className={styles.userContainer} onClick={() => window.open(process.env.REACT_APP_APP_URL, '_blank')}>
        <img alt={'user'} src={picture} className={styles.userImage}/>
      </div>
    </header>
  )
};

export default Header;
