import Header from '../../components/header';
import Table from '../../components/table';

import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import {
  EventCell,
  RateCell,
  ParticipationCell,
  DateCell,
  AddressCell, ButtonCell,
} from '../../components/table/renderers';
import { getEvents, getEventParticipants } from '../../services/api';

const MyEvents = (props) => {
  const [ events, setEvents ] = useState(null);

  const fetchData = async () => {
    const events = await getEvents();
    setEvents(events);
  }

  const downloadParticipants = async (eventId) => {
    const data = await getEventParticipants(eventId);
    const blob = new Blob([data], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'participants.csv';
    link.href = url;
    link.click();
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      Header: 'Evènement',
      accessor: 'title',
      Cell: EventCell,
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: DateCell,
    },
    {
      Header: 'Lieu',
      accessor: 'address',
      Cell: AddressCell,
    },
    {
      Header: 'Participation',
      accessor: 'total_places',
      Cell: ParticipationCell,
    },
    {
      Header: 'Taux',
      accessor: 'taken_places',
      Cell: RateCell,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (props) => <ButtonCell {...props} text={'Voir l\'évènement'} onClick={() => {
        window.open(`${process.env.REACT_APP_API_URL}/application/events?id=${props.row.original.id}`)
      }} />,
    },
    {
      Header: '',
      accessor: 'participants',
      Cell: (props) => <ButtonCell {...props} text={'Liste des participants'} onClick={() => downloadParticipants(props.row.original.id)} />,
    },
  ];

  return (
    <div className={styles.container}>
      <Header title={'Mes events'}/>
      {events && <Table columns={columns} data={events}/>}
    </div>
  )
}

export default MyEvents;
