import moment from 'moment';
import styles from './style.module.scss';
import { ReactComponent as Card } from '../../assets/svgs/Card.svg';
import Select from 'react-select';

const SinceCell = (props) => {
  const duration = moment.duration({ from: props.value, to: moment() });
  const value = duration.asDays();

  return (
    <p className={`${styles.since} ${value > 2 ? styles.danger : (value > 1 ? styles.warning : '')}`}>{duration.humanize()}</p>
  );
};


const ButtonCell = (props) => {
  return (
    <div className={styles.button} onClick={props.onClick}>{props.text}</div>
  );
}

const UserCell = (props) => {
  return (
    <div className={styles.userContainer}>
      <img alt={'user'} src={props.image || props.row.original.picture_url || 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} className={styles.userPicture}/>
      <p className={styles.userName}>{props.name || props.value}</p>
    </div>
  );
}

export const statusOptions = [
  { value: 'TO_CONTACT', label: 'A contacter' },
  { value: 'WAITING', label: 'En attente' },
  { value: 'SUBSCRIBING', label: 'Création de compte' },
];
const StatusCell = (props) => {
  return (
    <div className={styles.selectContainer}>
      <Select
        className={styles.select}
        options={statusOptions}
        defaultValue={statusOptions.find(o => o.value === props.value)}
        onChange={props.onChange}
      />
    </div>
  )
}

const NetworksCell = (props) => {
  return (
    <div className={styles.networksContainer}>
      {props.value ? (
        <img alt={'network'} src={props.value.logo_url} className={styles.networksLogo}/>
      ) : (
        <p className={styles.noNetworks}>Aucun</p>
        )}
    </div>
  );
}

const CardCell = (props) => {
  return (
    <div className={styles.cardContainer} onClick={props.onClick}>
      <Card className={styles.cardIcon}/>
    </div>
  )
}

const EventCell = (props) => {
  return (
    <div className={styles.eventContainer}>
      <img alt={'event'} src={props.row.original.picture_url} className={styles.eventPicture}/>
      <p className={styles.eventTitle}>{props.value}</p>
    </div>
  );
}

const DateCell = (props) => {
  return (
    <p className={styles.date}>{moment(props.value).format('DD/MM/YYYY')}</p>
  );
}

const AddressCell = (props) => {
  return (
    <div>
      <p className={styles.date}>{props.value.number} {props.value.street}</p>
      <p className={styles.date}>{props.value.zip_code} {props.value.city}</p>
    </div>
  );
}

const ParticipationCell = (props) => {
  return (
    <div className={styles.participationContainer}>
      <p className={styles.participationTaken}>{props.row.original.taken_places}</p>
      <p className={styles.participationTotal}>/{props.value}</p>
    </div>
  );
}

const RateCell = (props) => {
  const value = Math.round(props.row.original.taken_places / props.row.original.total_places * 100);
  let colorClassName = styles.valid;
  if (value < 67) colorClassName = styles.warning;
  if (value < 34) colorClassName = styles.danger;
  return (
    <p className={styles.rate + ' ' + colorClassName}>{value}%</p>
  );
}

const NetworkCell = (props) => {
  return (
    <div className={styles.networkContainer}>
      <img alt={'network'} src={props.row.original.logo_url} className={styles.networkPicture}/>
      <p className={styles.networkName}>{props.value}</p>
    </div>
  );
}

const NumberCell = (props) => {
  return (
    <div className={styles.numberContainer}>
      <p className={styles.number}>{props.value}</p>
    </div>
  );
}

const FollowTableCell = (props) => {
  console.log('props', props);
  return (
    <div className={styles.followTable}>
      <p className={
        (props.row.original.title.includes('Commission') ? styles.followTableImportant : '') +
        (props.row.original.title.includes('Total') ? (' ' + styles.followTableTotal) : '')
      }>{props.value}</p>
      {props.row.original.zone && props.column.id === 'title' && <span className={styles.subtitle}>{props.row.original.zone}</span>}
    </div>
  );
}

export {
  SinceCell,
  ButtonCell,
  UserCell,
  StatusCell,
  NetworksCell,
  CardCell,
  EventCell,
  DateCell,
  AddressCell,
  ParticipationCell,
  RateCell,
  NetworkCell,
  NumberCell,
  FollowTableCell,
};
