import Header from '../../components/header';
import Select from 'react-select';

import styles from './style.module.scss';
import { useEffect, useState } from 'react';

import { getEssentialProfile, getPromotionCodes, getQuota } from '../../services/api';

const options = Array.from(Array(50).keys()).map((i) => ({
  value: (i + 1).toString(), label: (i + 1).toString(),
}));
const types = [
  { value: 'year', label: 'Un an' },
  { value: 'life', label: 'A vie' },
];
const Codes = () => {
  const [ quota, setQuota ] = useState(0);
  const [ number, setNumber ] = useState(options[0]);
  const [ type, setType ] = useState(types[0]);
  const [ user, setUser ] = useState(null);
  const [ codes, setCodes ] = useState(null);

  const loadQuota = async () => {
    let user = await getEssentialProfile();
    setUser(user);
    const quota = await getQuota();
    setQuota(quota);
  }

  useEffect(() => {
    loadQuota();
  }, []);

  const getCodes = async () => {
    const codes = await getPromotionCodes(parseInt(number.value), type.value);
    setCodes(codes);
    // const blob = new Blob([codes.join('\n')], { type: "text/plain" });
    // const url = URL.createObjectURL(blob);
    // const link = document.createElement("a");
    // link.download = "codes.txt";
    // link.href = url;
    // link.click();
    await loadQuota();
  };

  return (
    <div className={styles.container}>
      <Header title={'Codes'}/>
      <div className={styles.content}>
        {(user?.world || user?.admin_zone_id || user?.administratedCityGroups?.length) && <div className={styles.codesContainer}>
          <h2 className={styles.title}>Comptes offerts</h2>
          {quota?.[type?.value] && <p className={styles.quota}>Comptes offerts restant : {quota[type.value]}</p>}
          <div className={styles.codesMaker}>
            <Select
              options={options}
              value={number}
              onChange={setNumber}
            />
            <div className={styles.spacer}/>
            <Select
              options={types.filter((t) => quota[t.value] > 0)}
              value={type}
              onChange={setType}
              defaultValue={'year'}
            />
            <div className={styles.button} onClick={getCodes}>
              <p>Générer les codes</p>
            </div>
          </div>
        </div>}
        {!!codes && <div className={styles.codesText}>
          {codes.map((c) => <p key={c}>{c}</p>)}
        </div>}
      </div>
    </div>
  )
}

export default Codes;
