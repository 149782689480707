import Header from '../../components/header';

import styles from './style.module.scss';
import { useEffect, useState } from 'react';

import { getAccount, configureAccount, getLoginLink } from '../../services/api';

const Billing = () => {
  const [ account, setAccount ] = useState(null);

  const loadQuota = async () => {
    const account = await getAccount();
    setAccount(account);
  }

  useEffect(() => {
    loadQuota();
  }, []);

  const _openAccount = async () => {
    const data = await getLoginLink();
    window.open(data.url.url, '_blank', 'noreferrer');
  }

  const _configureAccount = async () => {
    const data = await configureAccount();
    window.open(data.url, '_blank', 'noreferrer');
  }

  return (
    <div className={styles.container}>
      <Header title={'Ma facturation'}/>
      <div className={styles.content}>
         <div className={styles.billingContainer}>
          <h2 className={styles.title}>Facturation</h2>
          {!account && <span>Aucun compte renseigné</span>}
          {!!account && !account.charges_enabled && <span>Compte inactif</span>}
          {!!account && account.charges_enabled && <span>Compte actif</span>}
           {!!account && account.charges_enabled && <div className={styles.button} onClick={_openAccount}>
             <span>Voir mon compte de facturation</span>
           </div>}
          {(!account || !account.charges_enabled) && <div className={styles.button} onClick={_configureAccount}>
            <span>Configurer mon compte de facturation</span>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Billing;
