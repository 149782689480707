import Modal from 'react-modal';
import moment from 'moment';

import { ReactComponent as Close } from '../../assets/svgs/Close.svg';
import styles from './style.module.scss';

const UserModal = (props) => {
  if (!props.data) return null;

  const {
    fullname,
    picture_url,
    job,
    birthdate,
    network,
    phone,
    email_contact,
    company,
    createdAt,
    address,
  } = props.data;
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <img alt={'user'} src={picture_url || 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'} className={styles.picture}/>
        <h3 className={styles.fullname}>{fullname}</h3>
        <Close className={styles.closeIcon} onClick={props.onRequestClose}/>
      </div>
      <div className={styles.content}>
        <div className={styles.column}>
          {job && <h4 className={styles.title}>Métier</h4>}
          {job && <p className={styles.data}>{job}</p>}
          {/*<h4 className={styles.title}>Date de naissance</h4>*/}
          {/*<p className={styles.data}>{moment(birthdate).format('DD/MM/YYYY')}</p>*/}
          <h4 className={styles.title}>Regroupement</h4>
          <p className={styles.data}>{network?.name || 'Aucun'}</p>
          <h4 className={styles.title}>Contact</h4>
          <p className={styles.data + ' ' + styles.email} onClick={() => window.open(`mailto:${email_contact}`, '_blank')}>{email_contact}</p>
          <p className={styles.data}>{phone}</p>
        </div>
        <div className={styles.column}>
          {company && <h4 className={styles.title}>Entreprise</h4>}
          {company && <p className={styles.data}>{company}</p>}
          <h4 className={styles.title}>Date d'adhésion</h4>
          <p className={styles.data}>{moment(createdAt).format('DD/MM/YYYY')}</p>
          <h4 className={styles.title}>Siège social</h4>
          <p className={styles.data}>{address?.number} {address?.street}</p>
          <p className={styles.data}>{address?.zip_code} {address?.city}</p>
        </div>
      </div>
    </Modal>
  );
};

export default UserModal;
