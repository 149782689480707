const url = `${process.env.REACT_APP_API_URL}/dashboard`;
let token = '';

const getHeaders = (headers = {}) => {
  const token = localStorage.getItem('token');
  return {
    ...headers,
    'Authorization': token,
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
};

const getToken = async (authCode) => {
  try {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/auth/access_token?auth_code=${authCode}`);
    const data = await result.json();
    console.log('got token', data);
    token = data.token;
    localStorage.setItem('token', token);
  } catch (err) {
    console.error('Error getting token', err);
  }
};

const getEssentialProfile = async () => {
  try {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/profile/essential`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getEssentialProfile', err);
    return null;
  }
};

/* USERS */
const getProspects = async () => {
  try {
    const result = await fetch(`${url}/users/prospects`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getUsers', err);
    return null;
  }
};

const getCityGroups = async () => {
  try {
    const result = await fetch(`${url}/users/city-groups`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getUsers', err);
    return null;
  }
};

const getNewUsers = async () => {
  try {
    const result = await fetch(`${url}/users?includeNew=true`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getUsers', err);
    return null;
  }
};

const getAllUsers = async () => {
  try {
    const result = await fetch(`${url}/users?includeNew=false`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getUsers', err);
    return null;
  }
};

const onboardUser = async (userId) => {
  try {
    const result = await fetch(`${url}/users/${userId}/onboarding`, {
      method: 'PATCH',
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getUsers', err);
    return null;
  }
};

const setProspectStatus = async (userId, newStatus) => {
  try {
    const result = await fetch(`${url}/users/${userId}/prospectStatus`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({
        newStatus,
      })
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error setProspectStatus', err);
    return null;
  }
} ;

/* EVENTS */
const getEvents = async () => {
  try {
    const result = await fetch(`${url}/events`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getEvents', err);
    return null;
  }
};
const getEventParticipants = async (eventId) => {
  try {
    const result = await fetch(`${url}/events/participants?eventId=${eventId}`, {
      headers: getHeaders(),
    });
    const data = await result.text();
    return data;
  } catch (err) {
    console.error('Error getEventParticipants', err);
    return null;
  }
}

/* NETWORKS */
const getNetworks = async (allDepartment) => {
  try {
    const result = await fetch(`${url}/networks?allDepartment=${allDepartment}`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getUsers', err);
    return null;
  }
};

/* FOLLOW TABLE */
const getFollowTable = async (month, year) => {
  try {
    const result = await fetch(`${url}/followTable?month=${month}&year=${year}`, {
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}


/* GESTION */
const getQuota = async () => {
  try {
    const result = await fetch(`${url}/promotion-codes`, {
      method: 'GET',
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}

const getPromotionCodes = async (number, type) => {
  try {
    const result = await fetch(`${url}/promotion-codes`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        number,
        type,
      })
    });
    const data = await result.json();
    return data.codes;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}

const getAccount = async () => {
  try {
    const result = await fetch(`${url}/payments/status`, {
      method: 'GET',
      headers: getHeaders(),
    });
    const data = await result.json();
    return data.account;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}

const getLoginLink = async () => {
  try {
    const result = await fetch(`${url}/payments/login-link`, {
      method: 'GET',
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}

const configureAccount = async () => {
  try {
    const result = await fetch(`${url}/payments/configure`, {
      method: 'GET',
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}

/* STATISTICS */
const getStatistics = async () => {
  try {
    const result = await fetch(`${url}/statistics`, {
      method: 'GET',
      headers: getHeaders(),
    });
    const data = await result.json();
    return data;
  } catch (err) {
    console.error('Error getPromotionCodes', err);
    return null;
  }
}

export {
  getToken,
  getAllUsers,
  getEssentialProfile,
  getProspects,
  getCityGroups,
  getNewUsers,
  onboardUser,
  setProspectStatus,
  getEvents,
  getEventParticipants,
  getNetworks,
  getFollowTable,
  getPromotionCodes,
  getQuota,
  getAccount,
  configureAccount,
  getLoginLink,
  getStatistics,
};
