import { useEffect, useMemo, useState } from 'react';
import { getCityGroups, getStatistics } from '../../services/api';
import styles from './style.module.scss';
import Header from '../../components/header';
import Select from 'react-select';
import { statusOptions } from '../../components/table/renderers';
import classNames from 'classnames';
import { PieChart, Pie, Tooltip } from 'recharts';

const colors = ['#008AD1', '#BC1E2C', '#FAA60E' ];

const Statistics = (props) => {
  const [ baseData, setBaseData ] = useState(null);
  const [ cityGroup, setCityGroup ] = useState(null);

  const fetchData = async () => {
    const data = await getStatistics();
    setBaseData(data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const data = useMemo(() => {
    if (!cityGroup) return baseData;
    return baseData.cityGroups.find(cg => cg.cc === cityGroup);
  }, [ baseData, cityGroup ]);

  return (
    <div className={styles.container}>
      <Header title={'Dashboard'}/>
      {!!baseData?.cityGroups && <Select
        defaultValue={{ value: null, label: 'Toutes les CCs' }}
        options={[ { value: null, label: 'Toutes les CCs' }, ...baseData.cityGroups.map(cg => ({ value: cg.cc, label: cg.cc })) ]}
        onChange={({ value }) => setCityGroup(value)}
        className={styles.select}
      />}
      {!!data && <div className={styles.stats}>
        <div className={styles.statContainer}>
          <h3 className={styles.statName}>Membres payants</h3>
          <span className={classNames(styles.statValue)}>{data.payingMembers}</span>
        </div>
        <div className={styles.statContainer}>
          <h3 className={styles.statName}>Variation hebdomadaire</h3>
          <span className={classNames(styles.statValue, data.payingMembersWeekVariation.startsWith('+') && styles.statGreen)}>{data.payingMembersWeekVariation}</span>
        </div>
        <div className={styles.statContainer}>
          <h3 className={styles.statName}>Variation mensuelle</h3>
          <span className={classNames(styles.statValue, data.payingMembersMonthVariation.startsWith('+') && styles.statGreen)}>{data.payingMembersMonthVariation}</span>
        </div>
      </div>}
      {!!data && <div className={styles.cityGroups}>
        {!!baseData?.cityGroups && <div className={styles.leftCityGroup}>
          <h2 className={styles.cityGroupsTitle}>Répartition par Communautés de Communes</h2>
          <PieChart width={400} height={400}>
            <Pie
              data={baseData.cityGroups.filter(cg => cg.payingMembers !== 0).map(cg => ({ name: cg.cc, value: cg.payingMembers })).sort((a, b) => (a.value - b.value))}
              innerRadius={'60%'}
              outerRadius={'70%'}
              fill="#BC1E2C"
              paddingAngle={1}
              dataKey="value"
              startAngle={-270}
              label={({ percent }) => `${Math.round(percent * 100)}%`}
            />
            <Tooltip/>
          </PieChart>
        </div>}
        <div className={styles.rightCityGroup}>
          <div className={styles.cgStats}>
            <h2 className={styles.cityGroupsTitle}>Prospects</h2>
            <div className={styles.cgStatContainer}>
              <h3 className={styles.statName}>Nombre de prospects</h3>
              <span className={classNames(styles.statValue)}>{data.prospects.total}</span>
            </div>
            <div className={styles.cgStatContainer}>
              <h3 className={styles.statName}>Prospects traités</h3>
              <span className={classNames(styles.statValue)}>{data.prospects.handled}</span>
            </div>
            {data.prospects.averageTime && <div className={styles.cgStatContainer}>
              <h3 className={styles.statName}>Temps de traitement moyen</h3>
              <span className={classNames(styles.statValue)}>{data.prospects.averageTime}</span>
            </div>}
          </div>
          {data.prospects?.pie && <div className={styles.filter}>
            <PieChart width={400} height={400}>
              <Pie
                data={data.prospects.pie.map((v, i) => ({ ...v, fill: colors[i] }))}
                innerRadius={'40%'}
                outerRadius={'50%'}
                fill="#008AD1"
                paddingAngle={1}
                dataKey="value"
                startAngle={-270}
                label={({ name, percent }) => `${Math.round(percent * 100)}%`}
              />
              <Tooltip formatter={(value, name) => [`${Math.round(value * 100)}%`, name]}/>
            </PieChart>
          </div>}
        </div>
      </div>}
    </div>
  );
};

export default Statistics;
