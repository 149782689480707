import Header from '../../components/header';
import Table from '../../components/table';

import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import {
  SinceCell,
  ButtonCell, UserCell, NetworksCell, CardCell,
} from '../../components/table/renderers';
import UserModal from '../../components/userModal';
import { getNewUsers, onboardUser } from '../../services/api';

const MyNewMembers = (props) => {
  const [ users, setUsers ] = useState(null);
  const [ showUserModal, setShowUserModal ] = useState(false);
  const [ userData, setUserData ] = useState(null);

  const fetchData = async () => {
    const newUsers = await getNewUsers();
    setUsers(newUsers);
  }

  const sendOnboardUser = async (userId) => {
    try {
      await onboardUser(userId)
    } catch (err) {
      console.error('Error onboarding user', userId);
    } finally {
      await fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      Header: 'Prénom / Nom',
      accessor: 'fullname',
      Cell: UserCell,
    },
    {
      Header: 'Métier',
      accessor: 'job',
      Cell: (props) => {
        return (
          <p>{props.value?.length > 20 ? props.value.slice(0, 20) + '...' : props.value}</p>
        )
      }
    },
    {
      Header: 'Ville',
      accessor: 'city',
    },
    {
      Header: 'Regroupement',
      accessor: 'network',
      Cell: NetworksCell,
    },
    {
      Header: 'Téléphone',
      accessor: 'phone',
    },
    {
      Header: 'Payant',
      accessor: 'paying',
    },
    {
      Header: 'Fiche',
      accessor: 'informations',
      Cell: (props) => <CardCell {...props} onClick={() => {
        setShowUserModal(true);
        setUserData(props.row.original);
      }} />,
    },
    {
      Header: 'Depuis',
      accessor: 'createdAt',
      Cell: SinceCell
    },
    {
      Header: 'Statut',
      accessor: 'id',
      Cell: (props) => <ButtonCell {...props} text={'Je l\'ai contacté(e)'} onClick={() => {
        console.log('row', props)
        sendOnboardUser(props.row.original.id)
      }} />,
    },
  ];

  return (
    <div className={styles.container}>
      <Header title={'Nouveaux membres'}/>
      {users && <Table columns={columns} data={users}/>}
      <UserModal isOpen={showUserModal} onRequestClose={() => setShowUserModal(false)} data={userData}/>
    </div>
  )
}

export default MyNewMembers;
