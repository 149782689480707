import { ReactComponent as NewMemberIcon } from '../assets/svgs/NewMember.svg';
import { ReactComponent as MyMembersIcon } from '../assets/svgs/MyMembers.svg';
import { ReactComponent as MyEventsIcon } from '../assets/svgs/MyEvents.svg';
// import { ReactComponent as MyMediasIcon } from '../assets/svgs/MyMedias.svg';
import { ReactComponent as MyNetworksIcon } from '../assets/svgs/MyNetworks.svg';
import { ReactComponent as FollowTableIcon } from '../assets/svgs/FollowArray.svg';
import { ReactComponent as TeamsIcon } from '../assets/svgs/Teams.svg';
import { ReactComponent as StatisticsIcon } from '../assets/svgs/Statistics.svg';
import { FiSearch } from 'react-icons/fi';
import { MdOutlineDiscount } from 'react-icons/md';
import { HiOutlineWallet } from 'react-icons/hi2';

import FollowTable from './followTable';
import MyEvents from './myEvents';
// import MyMedias from './myMedias';
import MyMembers from './myMembers';
import MyNetworks from './myNetworks';
import MyNewMembers from './myNewMembers';
import Prospects from './prospects';
import Teams from './teams';
import Statistics from './statistics';
import Codes from './codes';
import Billing from './billing';

const routes = [
  { name: 'Dashboard', path: '/', Icon: StatisticsIcon, Element: Statistics },
  { name: 'Mes nouveaux membres', path: 'my-new-members', Icon: NewMemberIcon, Element: MyNewMembers },
  { name: 'Mes membres', path: 'my-members', Icon: MyMembersIcon, Element: MyMembers },
  { name: 'Prospects', path: 'prospects', Icon: FiSearch, Element: Prospects },
  { name: 'Groupements', path: 'my-networks', Icon: MyNetworksIcon, Element: MyNetworks },
  { name: 'Mes events', path: 'my-events', Icon: MyEventsIcon, Element: MyEvents },
  { name: 'Tableau de suivi', path: 'followup', Icon: FollowTableIcon, Element: FollowTable },
  { name: 'Equipes', path: 'teams', Icon: TeamsIcon, Element: Teams, disabled: true },
  { name: 'Codes', path: 'codes', Icon: MdOutlineDiscount, Element: Codes },
  { name: 'Ma facturation', path: 'billing', Icon: HiOutlineWallet, Element: Billing },
];

export default routes;
