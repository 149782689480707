import { useState, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './style.module.scss';

import { ReactComponent as Othr77 } from '../../assets/svgs/Othr77.svg';
import { ReactComponent as OthrTextWhite } from '../../assets/svgs/OthrTextWhite.svg';

import { getEssentialProfile, getNewUsers } from '../../services/api';

import routes from '../../routes';

const Menu = (props) => {
  const [ newMembers, setNewMembers ] = useState(0);
  const [ user, setUser ] = useState(null);

  const fetchData = async () => {
    const members = await getNewUsers()
    setNewMembers(members?.length);
    let user = await getEssentialProfile();
    setUser(user);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const data = useMemo(() => {
    if (!user) return {};
    return {
      fontSize: user.world ? 12 : 20,
      code: user.world ? 'FRANCE' : (user.administratedZone?.code || user.administratedCityGroups?.[0]?.zone?.code),
      title: user.world ? 'Directeur\nNational' : (user.admin_zone_id ? 'Directeur\nDépartemental' : 'Directeur local'),
      subtitle: user.world ? 'France' : (user.administratedZone?.name || user.administratedCityGroups?.map(cg => cg.name).join(' - ')),
    }
  }, [ user, user?.administratedZone, user?.administratedCityGroups, user?.world ]);

  return (
    <nav className={styles.container}>
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <Othr77 className={styles.logo} />
          <OthrTextWhite className={styles.othrText} />
          <p className={styles.departementText} style={{ fontSize: data.fontSize }}>{data.code}</p>
        </div>
        <div className={styles.headerTitleContainer}>
          <p className={styles.headerTitle}>{data.title}</p>
          <p className={styles.headerSubtitle}>{data.subtitle}</p>
        </div>
      </div>
      <ul className={styles.list}>
        {routes.map(({ name, path, Icon, disabled }) => (
          <li key={name} className={`${styles.menuItem} ${disabled ? styles.disabledLink : ''}`}>
            <Icon height={30} width={30} className={styles.icon} />
            <NavLink onClick={() => fetchData()} className={({ isActive }) => (`${styles.menuText} ${isActive ? styles.menuTextActive : ''} ${disabled ? styles.disabledLink : ''}`)} to={path}>
              <p>{name}</p>
            </NavLink>
            {name === 'Mes nouveaux membres' && newMembers > 0 && <div className={styles.notificationContainer}>
              <p className={styles.notification}>{newMembers}</p>
            </div>}
          </li>
        ))}
      </ul>
    </nav>
  )
};

export default Menu;
